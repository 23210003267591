export const cards = [
  {
    id: "Coinbase",
    title: "Coinbase",
    subtitle: "coinbase.com",
    img: "../../assets/coin.png",
  },
  {
    id: "ledgerLive",
    title: "Ledger Live",
    subtitle: "ledger.com",
    img: "../../assets/ledger.png",
  },
  {
    id: "metamask",
    title: "Metamask",
    subtitle: "metamask.io",
    img: "../../assets/metamask.jpg",
  },
  {
    id: "electrum",
    title: "Electrum",
    subtitle: "electrum.org",
    img: "../../assets/electrum.png",
  },
  {
    id: "exodus",
    title: "Exodus",
    subtitle: "exodus.com",
    img: "../../assets/exodus.png",
  },
  {
    id: "trust",
    title: "Trust Wallet",
    subtitle: "trustwallet.com",
    img: "../../assets/trust_wallet.jpg",
  },
  {
    id: "phantom",
    title: "Phantom",
    subtitle: "phantom.app",
    img: "../../assets/phantom.jfif",
  },
  {
    id: "Wallet Connect",
    title: "Wallet",
    subtitle: "walletconnect.com",
    img: "../../assets/wallet.jpeg",
  },
  {
    id: "Hashpack",
    title: "Hashpack",
    subtitle: "Hashpack.app",
    img: "../../assets/hash.jfif",
  },
  {
    id: "keplr",
    title: "Keplr",
    subtitle: "keplr.app",
    img: "../../assets/keplr.png",
  },
  {
    id: "klever",
    title: "Klever",
    subtitle: "klever.io",
    img: "../../assets/klev.png",
  },
  {
    id: "fantom",
    title: "Fantom",
    subtitle: "fantom.foundation",
    img: "../../assets/fantom.png",
  },
  {
    id: "blade",
    title: "Blade",
    subtitle: "bladewallet.io",
    img: "../../assets/blade.png",
  },
  {
    id: "Cosmos",
    title: "Cosmos",
    subtitle: "cosmos.network",
    img: "../../assets/cosmos.png",
  },
  {
    id: "avax",
    title: "Avax",
    subtitle: "wallet.avax.network",
    img: "../../assets/avax.png",
  },
  {
    id: "stacks",
    title: "Stacks wallet",
    subtitle: "stacks.co",
    img: "../../assets/stacks.png",
  },
  {
    id: "Terra",
    title: "Terra station",
    subtitle: "terra.money",
    img: "../../assets/terra.png",
  },
  {
    id: "polygon",
    title: "Polygon Wallet",
    subtitle: "polygon.technology",
    img: "../../assets/polygon.jpg",
  },
  {
    id: "rainbow",
    title: "Rainbow",
    subtitle: "rainbow.me",
    img: "../../assets/rainbow.jpg",
  },
  {
    id: "bitpay",
    title: "Bitpay",
    subtitle: "bitpay.com",
    img: "../../assets/bitpay.jpg",
  },
  {
    id: "walleth",
    title: "Walleth",
    subtitle: "walleth.org",
    img: "../../assets/walleth.jpg",
  },
  {
    id: "argent",
    title: "Argent",
    subtitle: "argent.xyz",
    img: "../../assets/argent.jpg",
  },
  {
    id: "huobi",
    title: "Huobi Wallet",
    subtitle: "huobiwallet.com",
    img: "../../assets/huobi.jpg",
  },
  {
    id: "Encrypted",
    title: "Encrypted Ink",
    subtitle: "encrypted.ink",
    img: "../../assets/encrypted_ink.jpg",
  },
  {
    id: "compound",
    title: "Compound",
    subtitle: "compound.finance",
    img: "../../assets/compound.jpg",
  },
  {
    id: "polkadot",
    title: "Polkadot",
    subtitle: "polkadot.network",
    img: "../../assets/polkadot.jpg",
  },
  {
    id: "Iotex",
    title: "Iotex",
    subtitle: "iotex.io",
    img: "../../assets/iotex.jpg",
  },
  {
    id: "coin98",
    title: "Coin98",
    subtitle: "coin98.com",
    img: "../../assets/coin98.jpg",
  },
  {
    id: "crypto",
    title: "Crypto.com | Defi Wallet",
    subtitle: "crypto.com",
    img: "../../assets/crypto.jpg",
  },
  {
    id: "token_pocket",
    title: "Token Pocket",
    subtitle: "tokenpocket.pro",
    img: "../../assets/token_pocket.jpg",
  },
  {
    id: "math_wallet",
    title: "Math Wallet",
    subtitle: "mathwallet.org",
    img: "../../assets/math_wallet.jpg",
  },
  {
    id: "1Inch",
    title: "1Inch",
    subtitle: "1inch.io",
    img: "../../assets/1inch.jpg",
  },
  {
    id: "Dharma",
    title: "Dharma",
    subtitle: "dharma.io",
    img: "../../assets/dharma.jpg",
  },
  {
    id: "trust_vault",
    title: "Trust Vault",
    subtitle: "trustology.io",
    img: "../../assets/trust_vault.jpg",
  },
  {
    id: "MYKEY",
    title: "MYKEY",
    subtitle: "mykey.org",
    img: "../../assets/mykey.jpg",
  },
  {
    id: "Atomic",
    title: "Atomic",
    subtitle: "atomicwallet.io",
    img: "../../assets/atomic.jpg",
  },
  {
    id: "CoolWallet S",
    title: "CoolWallet S",
    subtitle: "coolwallet.io",
    img: "../../assets/cool_wallet_s.jpg",
  },
  {
    id: "nash",
    title: "Nash",
    subtitle: "nash.io",
    img: "../../assets/nash.jpg",
  },
  {
    id: "coinomi",
    title: "Coinomi",
    subtitle: "coinomi.com",
    img: "../../assets/coinomi.jpg",
  },
  {
    id: "GridPlus",
    title: "GridPlus",
    subtitle: "gridplus.io",
    img: "../../assets/gridplus.jpg",
  },
  {
    id: "Tokenary",
    title: "Tokenary",
    subtitle: "tokenary.io",
    img: "../../assets/tokenary.jpg",
  },
  {
    id: "SafePal",
    title: "SafePal",
    subtitle: "safepal.io",
    img: "../../assets/safepal.jpg",
  },
  {
    id: "Infinito",
    title: "Infinito",
    subtitle: "infinitowallet.io",
    img: "../../assets/infinito.jpg",
  },
  {
    id: "Wallet.io",
    title: "Wallet.io",
    subtitle: "Wallet.io",
    img: "../../assets/wallet_io.jpg",
  },
  {
    id: "Ownbit",
    title: "Ownbit",
    subtitle: "ownbit.io",
    img: "../../assets/ownbit.jpg",
  },
  {
    id: "EasyPocket",
    title: "EasyPocket",
    subtitle: "easypocket.app",
    img: "../../assets/easypocket.jpg",
  },
  {
    id: "bridge_wallet",
    title: "Bridge Wallet",
    subtitle: "mtpelerin.com",
    img: "../../assets/bridge_wallet.jpg",
  },
  {
    id: "ViaWallet",
    title: "ViaWallet",
    subtitle: "viawallet.com",
    img: "../../assets/via_wallet.jpg",
  },
  {
    id: "BitKeep",
    title: "BitKeep",
    subtitle: "bitkeep.com",
    img: "../../assets/bitkeep.jpg",
  },
  {
    id: "unstoppable_wallet",
    title: "Unstoppable Wallet",
    subtitle: "unstoppable.money",
    img: "../../assets/unstoppable_wallet.jpg",
  },
  {
    id: "dok_wallet",
    title: "Dok Wallet",
    subtitle: "dokwallet.com",
    img: "../../assets/dok_wallet.jpg",
  },
  {
    id: "haloDefi_wallet",
    title: "HaloDefi Wallet",
    subtitle: "halodefi.org",
    img: "../../assets/halodefi_wallet.jpg",
  },
  {
    id: "cello_wallet",
    title: "Cello Wallet",
    subtitle: "cellowallet.app",
    img: "../../assets/celo_wallet.jpg",
  },
  {
    id: "CoinUs",
    title: "CoinUs",
    subtitle: "coinus.io",
    img: "../../assets/coinus.jpg",
  },
  {
    id: "Valora",
    title: "Valora",
    subtitle: "valoraapp.com",
    img: "../../assets/valora.jpg",
  },
  {
    id: "trustee_wallet",
    title: "Trustee Wallet",
    subtitle: "trusteeglobal.com",
    img: "../../assets/trustee_wallet.jpg",
  },
  {
    id: "gaurda_wallet",
    title: "Gaurda Wallet",
    subtitle: "guarda.com",
    img: "../../assets/guarda_wallet.jpg",
  },
  {
    id: "maiar_wallet",
    title: "Maiar Wallet",
    subtitle: "Maiar.com",
    img: "../../assets/maiarwallet.png",
  },
  {
    id: "jade_wallet",
    title: "Jade Wallet",
    subtitle: "jadewallet.io",
    img: "../../assets/jade_wallet.jpg",
  },
  {
    id: "plasmaPay",
    title: "PlasmaPay",
    subtitle: "plasmapay.com",
    img: "../../assets/plasmapay.png",
  },
  {
    id: "O3Wallet",
    title: "O3Wallet",
    subtitle: "o3.network",
    img: "../../assets/o3_wallet.jpg",
  },
  {
    id: "hashKey_me",
    title: "HashKey Me",
    subtitle: "me.hashkey.com",
    img: "../../assets/hashkey_me.jpg",
  },
  {
    id: "RWallet",
    title: "RWallet",
    subtitle: "rsk.co",
    img: "../../assets/rwallet.jpg",
  },
  {
    id: "flare_wallet",
    title: "Flare Wallet",
    subtitle: "flarewallet.io",
    img: "../../assets/flare_wallet.jpg",
  },
  {
    id: "kyberswap",
    title: "KyberSwap",
    subtitle: "kyberswap.com",
    img: "../../assets/kyberswap.jpg",
  },
  {
    id: "aToken_wallet",
    title: "AToken Wallet",
    subtitle: "atoken.com",
    img: "../../assets/atoken_wallet.jpg",
  },
  {
    id: "tongue_wallet",
    title: "Tongue Wallet",
    subtitle: "tongue.fi",
    img: "../../assets/tongue_wallet.jpg",
  },
  {
    id: "XinFin XDC Network",
    title: "XinFin XDC Network",
    subtitle: "xinfin.io",
    img: "../../assets/xinfin.jpg",
  },
  {
    id: "Talken Wallet",
    title: "Talken Wallet",
    subtitle: "talken.io",
    img: "../../assets/talken_wallet.jpg",
  },
  {
    id: "KEYRING PRO",
    title: "KEYRING PRO",
    subtitle: "keyring.app",
    img: "../../assets/keyring_pro.jpg",
  },
  {
    id: "Midas Wallet",
    title: "Midas Wallet",
    subtitle: "midasprotocol.io",
    img: "../../assets/midas_wallet.jpg",
  },
  {
    id: "AT.Wallet",
    title: "AT.Wallet",
    subtitle: "authentrend.com",
    img: "../../assets/at_wallet.jpg",
  },
  {
    id: "imToken",
    title: "imToken",
    subtitle: "token.im",
    img: "../../assets/imtoken.jpg",
  },
  {
    id: "Others",
    title: "Others",
    subtitle: "",
    img: "../../assets/imtoken.jpg",
  },
];
