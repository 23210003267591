import React from "react";
import Card from "react-bootstrap/Card";

function CardTemplate({ title, text, icon, onClick }) {
  return (
    <Card className="card-bg" onClick={onClick}>
      <Card.Body>
        <p className="icons">{icon}</p>
        <Card.Title className="title">{title}</Card.Title>
        <Card.Text className="card-text">{text}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export default CardTemplate;
