import React from "react";

export default function Loader() {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 20,
            backgroundColor: '#fbfbfbe0',
            width: '100%'
        }}>
            <img
                src="../../assets/spinner.gif"
                alt="loading..."
                style={{
                    width: '40em',
                    height: '40em'
                }}
            />
        </div>
    )
}