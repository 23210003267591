import React from "react";
import Card from "react-bootstrap/Card";

function WalletCard({ title, subtitle, img, onClick }) {
  return (
    <Card onClick={onClick} className="wallet-bg">
      <Card.Body className="wallet">
        <img src={img} alt="" className="icon" />
        <div className="parent">
          <Card.Title className="title">{title}</Card.Title>
          <Card.Text className="card-text">{subtitle}</Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
}

export default WalletCard;
