import React, { useEffect, useState, useRef } from 'react';
import { SiCoinmarketcap } from "react-icons/si";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles.css';
import axios from 'axios';
import Coin from './coin';
import { useNavigate } from 'react-router-dom';

const NavSlider = () => {
    const [getAllCoins, setAllCoins] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const sliderRef = useRef(null);

    const linkToGo = (link) => {
        navigate();
    };

    const apiUrl = 'https://api.geckoterminal.com/api/v2/tokens/info_recently_updated';

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(apiUrl);
                setAllCoins(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
        };

        // Fetch data only if getAllCoins is empty
        if (!getAllCoins || getAllCoins.length === 0) {
            fetchData();
        }
    }, []);

    const settings = {
        infinite: true,
        speed: 13000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, // Set to 0 for continuous scrolling
        cssEase: 'linear',
        vertical: false,
        pauseOnHover: false, // Disable pausing on hover
    };

    const handleSliderPause = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPause();
        }
    };

    const handleSliderPlay = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPlay();
        }
    };

    return (
        <div
            className="marquee-slider-container"
            onMouseEnter={handleSliderPause}
            onMouseLeave={handleSliderPlay}
        >
            <Slider ref={sliderRef} {...settings}>
                <div className="slide">
                    <div className="slide_items_container">
                        {getAllCoins.slice(0, 50).map((coin) => (
                            <Coin
                                key={coin.id}
                                name={coin.attributes.name}
                                network={coin.relationships.network.data.id}
                                url={coin.attributes.image_url}
                                rate={coin?.attributes?.gt_score}
                                onClick={() => linkToGo(coin.attributes.websites[0])}
                            />
                        ))}
                    </div>
                </div>
                <div className="slide">
                    <div className="slide_items_container">
                        {getAllCoins.slice(50, 100).map((coin) => (
                            <Coin
                                key={coin.id}
                                name={coin.attributes.name}
                                network={coin.relationships.network.data.id}
                                url={coin.attributes.image_url}
                                rate={coin?.attributes?.gt_score}
                                onClick={() => linkToGo(coin.attributes.websites)}
                            />
                        ))}
                    </div>
                </div>
            </Slider>

            <div className='slider_fixed_bar'>
                <div>
                    <div className='slider_fixed_bar_txt'>Powered by</div>
                    <div className='slider_fixed_bar_logo'>
                        <SiCoinmarketcap className='slider_fixed_bar_logo_icon' />
                        <span>CoinMarketCap</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavSlider;
