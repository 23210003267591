import React from 'react';
import { RiSearchLine } from "react-icons/ri";
import { AiFillWallet } from "react-icons/ai";
import { SiWalletconnect } from "react-icons/si";

export default function Nav() {
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Adds smooth scrolling animation
        });
    };
    return (
        <div className='nav_container'>
            <div className='nav'>
                <div className='nav_logo'>
                    <SiWalletconnect />
                </div>
                <div className='nav_sect2'>
                    <div>
                        <RiSearchLine className='nav_icon' />
                    </div>
                    <div className='nav_wallet'>
                        <AiFillWallet className='nav_icon' />
                        <div
                            onClick={handleScrollToTop}
                            className='nav_wallet_text'>Connect Wallet</div>
                    </div>
                </div>
            </div>
        </div>
    )
}