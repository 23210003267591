import React from "react";
import './styles.css'

export default function Coin(props) {
    const { name, network, onClick, url, rate } = props
    return (
        <div onClick={onClick} className="coin_container">
            <div className="coin_details">
                <div className="coin_img_container">
                    <img src={url} alt="" />
                </div>
                <div className="coin_title_container">
                    <div className="coin_title">{name !== null ? name.slice(0, 8) : ''}</div>
                    <div className="coin_network">{network !== null ? network.slice(0, 5) : ''}</div>
                </div>
            </div>
            <div className="coin_values">
                <div className="coin_title">${rate !== null ? rate.toString().slice(0, 4) : ''}</div>
                <div className="coin_rate">0.01%</div>
            </div>
        </div>
    )
}