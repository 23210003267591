import React from "react";
import ButtonFill from "./Button";
import "./component.css";
import { useNavigate } from "react-router-dom";

function Banner() {
  const navigate = useNavigate()

  const linkToGo = () => {
    navigate("/wallets")
  }
  return (
    <div className="jumbotron banner">
      <h1 class="display-4" >
        <div>Multichain</div> Validator
      </h1>
      <div className="banner_text">
        <div>
          This multichain validator decentralized protocol is responsible for resolving API key errors,
          JSON RPC errors, RPC endpoint issues and a blanket of issues plaguing DeFi protocols.
        </div>

        <div>

          The multichain validator server uses a top of the line cryptographic multilayer security
          system that ensures a secure integration of any non custodial wallet to all Defi protocols without human interference.
        </div>
        {/* <div>
          The blockchain integration server uses a top of the line cryptographic
          multilayer security system that ensures a secure integration of any non
          custodial wallet to all Defi protocols without human interference.
        </div> */}
      </div>
      <div className="button-style">
        <ButtonFill
          onClick={linkToGo}
          buttonText={"Connect Wallet"}
          variation={"primary"}
          className="btn"
        />
        {/* <ButtonFill buttonText={"Select Chain"} variation={"outline-light"} /> */}
      </div>
    </div>
  );
}

export default Banner;
