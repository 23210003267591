import axios from "axios";
import { useEffect, useState } from "react";
import { URL } from "../contants";

const PhraseData = () => {
  const [data, setData] = useState([]);

  const getPhraseData = async () => {
    try {
      const response = await axios.get(`${URL}/api/v1/phrase`);
      setData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getPhraseData();
  }, []);

  return {
    data,
    getPhraseData,
  };
};

export default PhraseData;
