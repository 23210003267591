import React from "react";
import Button from "react-bootstrap/Button";

function ButtonFill({ buttonText, variation, onClick }) {
  return (
    <Button variant={variation} size="lg" onClick={onClick}>
      {buttonText}
    </Button>
  );
}

export default ButtonFill;
