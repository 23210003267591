import React, { useState } from "react";
import "./AdminLogin.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { URL } from "../../contants";

const AdminLogin = () => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      try {
        const { email, password } = values;
        const { data } = await axios.post(
          `${URL}/api/v1/admin/signin`,
          {
            email,
            password,
          }
        );

        if (data.success === true) {
          //localstorage

          localStorage.setItem("adminSafeUser", JSON.stringify(data.user));
          toast(data.message, toastOptions);

          setTimeout(() => {
            navigate("/adminD");
          }, 2000);
          return null;
        }
      } catch (error) {
        toast.error("Failed to signin", toastOptions);
      }
    }
  };

  const handleValidation = () => {
    const { email, password } = values;
    if (email === "") {
      toast.error("Please enter a valid credentials", toastOptions);
      return false;
    } else if (password === "") {
      toast.error("Please enter password", toastOptions);
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  return (
    <div className="body">
      <div className="wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6 rola text-center secondContainer">
              <div className="card">
                <div className="py-3 px-2">
                  <h5>Admin User</h5>
                </div>
                <div className="card-body">
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group py-1">
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        onChange={(e) => handleChange(e)}
                        id=""
                        aria-describedby="helpId"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="form-group py-1">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        onChange={(e) => handleChange(e)}
                        id=""
                        aria-describedby="helpId"
                        placeholder="Password"
                      />
                    </div>
                    <input
                      type="submit"
                      value="Log In"
                      className="mt-2 btn btn-primary w-100 "
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AdminLogin;
