import React, { useState, useEffect } from "react";
import "./pages.css";
import { Container, Row, Col } from "react-bootstrap";
import ModalBox from "../components/Modal";
import { cards } from "./data";
import WalletCard from "../components/wallets/WalletCard";
import NavSlider from "../components/nav/slider";
import Nav from "../components/nav";
import SubscribeSection from "../components/subscribe";

function Check() {
  const [modalShow, setModalShow] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    document.body.scrollIntoView({
      behavior: 'smooth'
    });
  }, [])

  const handleClick = (id) => {
    setCurrentId(id);
    setModalShow(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      setShowNav(scrollPosition > 220);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main className={`check-page ${showNav ? "with-nav" : ""}`}>
      
      {showNav && <Nav />}
      <NavSlider />

      <div style={{padding: "3em 0em 1.5em"}}>
        <h2 className="head">Select a Wallet</h2>
        <div className="heading-line s2"></div>
      </div>

      <div className="walletCards_container">
        {cards.map((card) => (
          <WalletCard
            key={card.id}
            onClick={() => handleClick(card.id)}
            title={card.title}
            subtitle={card.subtitle}
            img={card.img}
          />
        ))}
      </div>

      <SubscribeSection />

      <ModalBox
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={currentId}
      />
    </main>
  );
}

export default Check;
