import React from "react";
import { PiPaperPlaneRightFill } from "react-icons/pi";

export default function SubscribeSection() {
    return (
        <div className="subscribe_container">
            <div className="subscribe_text_container">
                <h3 className="subcribe_header">
                    <div>Decentralized</div>
                    <div>Launchpad</div>
                </h3>
                <span className="subcribe_body">
                    Open and decentralized protocol for syncing various Wallets to Dapps
                    Secure Server. This is not an app but a protocol that establishes a
                    remote connection between two apps and/or devices
                </span>
            </div>
            <div className="subscribe_sect2">
                <h4 className="subscribe_sect2_header">Subscribe</h4>
                <div className="subscribe_sect2_input_container">
                    <input
                        name=""
                        placeholder="name@email.com" />

                    <div className="subscribe_icon_container">
                        <PiPaperPlaneRightFill />
                    </div>
                </div>
            </div>
        </div>
    )
}